import { AppBar, Avatar, Button, Container, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import AdbIcon from '@mui/icons-material/Adb'
import ScrollToColor from '../ScrollToColor';
import Images from '../../assets/Images';
import { Link } from 'react-scroll';

// const pages = ['Home', 'FAQ', 'Contact Us'];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <>
    <ScrollToColor>
      <AppBar position="sticky">
        <Container>
          <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link  to='top' spy={true} smooth={true} offset={-80} duration={500}
            style={{
              cursor: 'pointer'
              }}
              >
              <img src={Images.logo} width='60px'/>
            </Link>
            {/* For Menu  */}
            {/* <Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                  <Button
                    key={page}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'inherit', display: 'block' }}
                  >
                    {page}
                  </Button>
                ))}
              </Box>
            </Box> */}
            <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                 
                  <Link to='register' spy={true} smooth={true} offset={-80} duration={500}
                  style={{
                     cursor: 'pointer'
                     }}> 
                  Register
                  </Link>
            </Box>
          </Toolbar>
          </Container >
      </AppBar>
      </ScrollToColor>
    </>
  )
}

export default Header