const Images={
    appImage:require("./screenshotiphone.png"),
    bottomShape:require("./hero-1-bottom-shape.png"),
    playstore:require("./play-store.png"),
    istore:require("./i-store.png"),
    logo:require("./logo.png"),
    featuresbg:require("./features-bg.png"),
    features1:require("./features-1.png"),
    thankyou:require("./Phones.png")


}
export default Images