import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Images from "../../assets/Images";
import Header2 from "../../components/Header/Header2";

const Thankyou = () => {
  return (
    <>
    <Header2/>
      <Grid container>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "35px",
                fontWeight: 700,
                textAlign: "center",
                
              }}
            >
              You've been added to <br/> our waiting list  &#127881;
            </Typography>
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                mt: 3,
              }}
            >
            <Grid container sx={{ width: "60%" }}>
                <Grid item md={6} xs={12}>
                  <img
                    src={Images.playstore}
                    alt="playstore"
                    width="170px"
                    
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <img
                    src={Images.istore}
                    alt="appstore"
                    width="170px"
                    
                  />
                </Grid>
              </Grid>
              </Box>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <img src={Images.thankyou} width="100%" />
        </Grid>
        
      </Grid>
      
    </>
  );
};

export default Thankyou;
