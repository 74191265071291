import { useScrollTrigger } from "@mui/material";
import React from "react";

const ScrollHandler = props => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: props.window ? window() : undefined
  });

  return React.cloneElement(props.children, {
    style: {
      background: trigger ? "linear-gradient(to bottom, #1583ca 30%, #2c96db 100%)" : "#1583CA",
      color: trigger ? "white" : "white",
      transition: trigger ? "0.3s" : "0.3s",
      boxShadow: "none",
      padding: "10px 0px"
    }
  });
};

const ScrollToColor = props => {
  return <ScrollHandler {...props}>{props.children}</ScrollHandler>;
};

export default ScrollToColor;