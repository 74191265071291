const District=[
    {   id:'1',
        name:'Central'
    },
    {   id:'2',
        name:'East'
    },
    {   id:'3',
        name:'South'
    },
    {   id:'4',
        name:'West'
    },
]

export default District