
import Header from './components/Header/Header';
import { createTheme, ThemeProvider } from '@mui/material';
import Home from './views/Home/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Thankyou from './views/ThankYou/Thankyou';
const theme = createTheme({
  typography: {
    fontFamily: [
      "Lato",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
     <BrowserRouter>
     
     <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/thank-you" element={<Thankyou/>}/>
     </Routes>
     <Footer/>
     </BrowserRouter>

    </ThemeProvider>
  );
}

export default App;
