import { Box, Typography } from '@mui/material'
import React from 'react'

const Footer = () => {
  return (
    <Box sx={{ textAlign: 'center', backgroundColor: '#1583CA', color: 'white',p:2 }}>
    <Typography>2022 © All Right Reserve Developed By MangoTech Solutions</Typography>
</Box>
  )
}

export default Footer