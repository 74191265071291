import { Apis, get, post, put } from '.';

export const Service = {
    register: async (obj) => {
        let result = await post(Apis.register, obj);
        if (result.status === 200) return result.data;
        else throw result;
    }


}