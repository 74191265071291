import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, {useState } from "react";
import Images from "../../assets/Images";
import Typewriter from "typewriter-effect";
import DoneIcon from "@mui/icons-material/Done";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useForm } from "react-hook-form";
import Neighbourhood from "../../utils/Neighbourhood";
import District from "../../utils/District";
import { useNavigate } from "react-router-dom";
import { Service } from "../../config/service";
import Header from "../../components/Header/Header";
const style = {
  box: {
    border: "1px solid #E4E4E4",
    borderRadius: "10%",
    p: 1.2,
    mr:1,
    mb:1,
    cursor: "pointer",
    width:'78px',
    textAlign:'center'
  },
};
const Home = () => {
    let navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ["Basic Details", "Routes Details"];
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue,
    clearErrors,
  } = useForm();

  const [districtByNeighbourhood, setDistrictByNeighbourhood] = useState([]);
  const [districtByNeighbourhoodD, setDistrictByNeighbourhoodD] = useState([]);
  const getNeighbourhoodPick = (value) => {
    setValue("pDistrict", value.id);
    const result = Neighbourhood.filter((e) => e.district === value.id);
    setDistrictByNeighbourhood(result);
    clearErrors("pDistrict");
  };
  const getNeighbourhoodDrop = (value) => {
    setValue("dDistrict", value.id);
    const result = Neighbourhood.filter((e) => e.district === value.id);
    setDistrictByNeighbourhoodD(result);
    clearErrors("dDistrict");
  };

  const [travelDays, setTravelDays] = useState([]);

  const handleDayYouTravel = (value) => {
    const array = [...travelDays];
    const index = travelDays.indexOf(value);
    if (index === -1) {
      array.push(value);
    } else {
      array.splice(index, 1);
    }
    setTravelDays(array);
  };

  const [timeValue, setTimeValue] = useState(new Date(Date.now()));

  const handleChange = (newValue) => {
    setValue("pTime", newValue);
    setTimeValue(newValue);
    clearErrors("pTime");
  };
  const [basicDetails, setbasicDetails] = useState();
  const onSubmit = async (formData) => {
    if(formData.phone){
        setbasicDetails(formData)
        handleBackClick();
    }
    if(formData.dNeighbourhood){
        try {
            let isTrueSet = (isDriver.toLowerCase() === 'true');
            let obj={
                name:basicDetails.name,
                email:basicDetails.email,
                phone:basicDetails.phone,
                gender:basicDetails.gender,
                dropOfDetails:{
                    district:formData.dDistrict,
                    area:formData.dNeighbourhood,
                    city:'karachi'
                },
                dayYouTravel:travelDays,
                pickUpDetails:{
                    district:formData.pDistrict,
                    area:formData.pNeighbourhood,
                    time:formData.pTime,
                    city:'karachi',
                },
                isDriver:isTrueSet
            }
            const{responseCode,status,message}=await Service.register(obj)
            if (status === true && responseCode === 200) {
                navigate('/thank-you')
            }
        } catch (error) {
            console.log(error)
        }
    }
    
  };
  function handleBackClick() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }
  const [isDriver, setIsDriver] = useState();
  return (
    <>
    <Header/>
      <Box
        sx={{
          background: "linear-gradient(to bottom, #1583ca 0%, #47b1f5 104%)",
        }}
      >
        <Container id="top">
          <Grid container spacing={2} sx={{ pt: 1 }}>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <Box sx={{ color: "white" }}>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 700,
                      fontSize: "2.9rem",
                    }}
                  >
                    Ready, Steady, and
                    <span
                      style={{
                        display: "flex",
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                        color: "#FFBC00",
                      }}
                    >
                      <Typewriter
                        options={{
                          autoStart: true,
                          loop: true,
                        }}
                        onInit={(tw) => {
                          tw.typeString("WeeGo!").pauseFor(2500).start();
                        }}
                      />
                      {/* <span style={{color:'white'}}>with you!</span> */}
                    </span>
                  </Typography>
                  <Typography sx={{ fontSize: "25px" }}>
                    Earn money with pride, or book a stress-free ride, You
                    decide!
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                zIndex: "1",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box data-aos="zoom-in-left" data-aos-duration="3000">
                <img src={Images.appImage} width="100%" />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          position: "relative",
          top: "-280px",
          height: "50px",
        }}
      >
        <img src={Images.bottomShape} width="100%" />
      </Box>
      <Box sx={{ background: "#EBEBEB", py: 6 }}>
        <Container>
          <Box sx={{ textAlign: "center", color: "#6c757d" }}>
            <Typography
              sx={{
                fontSize: "1.3rem",
              }}
            >
              {" "}
              <span style={{ fontWeight: 700, color: "black" }}>
                A One-Stop Solution!
              </span>
            </Typography>
            <Typography
              sx={{
                fontSize: "2.4rem",
                fontWeight: 700,
                textAlign: "center",
                color: "black",
              }}
            >
              Smart Solutions For People
            </Typography>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid item md={6} xs={12}>
                <Typography sx={{ fontSize: "1.2rem" }}>
                  Providing our Expertise, That guarantees,
                  <br />
                  The solution to your problem, with Ease!
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid
            container
            sx={{
              mt: 12,
              background: `url(${Images.featuresbg}) no-repeat fixed center`,
            }}
          >
            <Grid item md={6} xs={12}>
              <img src={Images.features1} width="100%" />
            </Grid>
            <Grid item md={6} xs={12}>
              <Box
                sx={{
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "40px",
                    fontWeight: 700,
                    textAlign: "center",
                  }}
                >
                  Features
                </Typography>
                <Typography sx={{ color: "#6c757d", textAlign: "center" }}>
                  Request a driver, or Create a Ride! Low-cost, On-demand rides
                  & stress-free rides, A one-stop solution!
                </Typography>
                <Box
                  sx={{ display: "flex", mt: 3 }}
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration="2500"
                >
                  <Avatar
                    sx={{
                      flexShrink: 0,
                      color: "#1f61eb",
                      backgroundColor: "white",
                    }}
                  >
                    <DoneIcon />
                  </Avatar>
                  <Typography sx={{ flexGrow: 1, ml: "1.3rem" }}>
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      Search A Driver!
                    </span>
                    <br />
                    Tap <span style={{ fontWeight: "bold" }}>
                      Search Ride
                    </span>{" "}
                    for immediate pickup, or tap 'Ride Later' to reserve a ride
                    in advance. Be your own boss and travel whenever it works
                    for you...
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", mt: 3 }}
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration="1500"
                >
                  <Avatar
                    sx={{
                      flexShrink: 0,
                      color: "#1f61eb",
                      backgroundColor: "white",
                    }}
                  >
                    <DoneIcon />
                  </Avatar>
                  <Typography sx={{ flexGrow: 1, ml: "1.3rem" }}>
                    <span style={{ fontWeight: "bold" }}>
                      Search A Traveller!
                    </span>{" "}
                    <br />
                    Tap <span style={{ fontWeight: "bold" }}>Create Ride </span>
                    for an immediate search for any traveller, Earn your money,
                    keep your tips, and cash out when you want...{" "}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container sx={{ p: 10 }}>
        <Typography
          sx={{
            fontSize: "2.4rem",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          How does it work ?
        </Typography>
        {/* <Grid container sx={{ justifyContent: 'center' }}>
                    <Grid item md={6} md={12} >
                        <Typography sx={{ mb: 3, color: '#6c757d', textAlign: 'center', fontSize: '18px' }}>
                            Sed ut perspiciatis unde omnis iste natus error voluptatem
                            accusantium doloremque rem aperiam.
                        </Typography>
                    </Grid>
                </Grid> */}
        <Grid container>
          <Grid item md={4} xs={12}>
            <Box sx={{ p: "1.3rem", display: "flex" }}>
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "28% 72% 50% 50%/26% 20% 80% 74%",
                    backgroundColor: "rgba(31, 97, 235, 0.15)",
                    padding: "10px",
                    width: "fit-content",
                  }}
                >
                  <ArrowDownwardIcon style={{ fontSize: 86 }} />
                </Box>
                <Box>
                  <Typography
                    sx={{ fontSize: "1.25rem", fontWeight: 700, my: 2 }}
                  >
                    1. Download our App!
                  </Typography>
                  <Typography sx={{ color: "#6c757d ", fontSize: "18px" }}>
                    Download our app from Playstore or Appstore in just a click!
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <img
                  src="https://coderthemes.com/appexy/layouts/images/arrow-top.png"
                  alt="arrow"
                  style={{
                    marginTop: "25px",
                    height: "fit-content",
                    width: "fit-content",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box sx={{ p: "1.3rem", display: "flex" }}>
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "28% 72% 50% 50%/26% 20% 80% 74%",
                    backgroundColor: "rgba(255, 194, 18, 0.15) ",
                    padding: "10px",
                    width: "fit-content",
                  }}
                >
                  <DirectionsCarIcon style={{ fontSize: 86 }} />
                </Box>
                <Box>
                  <Typography
                    sx={{ fontSize: "1.25rem", fontWeight: 700, my: 2 }}
                  >
                    2. Select A Ride
                  </Typography>
                  <Typography sx={{ color: "#6c757d ", fontSize: "18px" }}>
                    Book a hassle-free ride, or earn your money with pride!
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <img
                  src="https://coderthemes.com/appexy/layouts/images/arrow-top.png"
                  alt="arrow"
                  style={{
                    marginTop: "25px",
                    height: "fit-content",
                    width: "fit-content",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box sx={{ p: "1.3rem", display: "flex" }}>
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "28% 72% 50% 50%/26% 20% 80% 74%",
                    backgroundColor: "rgba(28, 225, 172, 0.15)",
                    padding: "10px",
                    width: "fit-content",
                  }}
                >
                  <DoneIcon style={{ fontSize: 86 }} />
                </Box>
                <Box>
                  <Typography
                    sx={{ fontSize: "1.25rem", fontWeight: 700, my: 2 }}
                  >
                    3. And WeeGo!
                  </Typography>
                  <Typography sx={{ color: "#6c757d ", fontSize: "18px" }}>
                    Ready to go, with the stress-free solution to your problems!
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Box id="register" sx={{ background: "#EBEBEB", py: 6 }}>
        <Container>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: "2.4rem",
                fontWeight: 700,
                textAlign: "center",
                mb: 3,
              }}
            >
              Register
            </Typography>
          </Box>
          {!isDriver && (
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid container sx={{ width: "50%" }}>
                <Grid item md={6} xs={12}>
                  <Button
                    sx={{ fontSize: "1.5rem", mb: { xs: 3, md: 0 } }}
                    variant="contained"
                    onClick={() => setIsDriver('false')}
                  >
                    Are You Passenger
                  </Button>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Button
                    sx={{ fontSize: "1.5rem" }}
                    variant="contained"
                    onClick={() => setIsDriver('true')}
                  >
                    Are You Driver
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
          {isDriver && (
            <Card sx={{ minWidth: 275, p: 3, borderRadius: "20px" }}>
              <CardContent>
                <Box sx={{ width: "100%" }}>
                  <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel color="inherit">
                          <span style={{ fontSize: "20px" }}>{label}</span>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  {activeStep === 0 && (
                    <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
                      <Box
                        sx={{
                          border: "1px solid #dfd9d9",
                          borderRadius: "10px",
                          p: 2,
                          mt: 2,
                        }}
                      >
                        <Grid container>
                          <Grid item md={6} xs={12} sx={{ mb: 2 }}>
                            <Typography sx={{ fontSize: "18px", mb: 1 }}>
                              Name:
                            </Typography>
                            <FormControl sx={{ width: "90%" }}>
                              <TextField
                                variant="standard"
                                placeholder="Enter Your Name"
                                {...register("name", {
                                  required: "Name is Required",
                                })}
                                InputProps={{
                                  style: { fontSize: 20 },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <PersonIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {errors.name && (
                                <span style={{ color: "red" }}>
                                  {errors.name.message}
                                </span>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item md={6} xs={12} sx={{ mb: 2 }}>
                            <Typography sx={{ fontSize: "18px", mb: 1 }}>
                              Email:
                            </Typography>
                            <FormControl sx={{ width: "90%" }}>
                              <TextField
                                variant="standard"
                                placeholder="Enter Your Email"
                                {...register("email", {
                                  required: "Email is Required",
                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message:
                                      "Entered Value Does not Match Email Format",
                                  },
                                })}
                                InputProps={{
                                  style: { fontSize: 20 },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <EmailIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {errors.email && (
                                <span style={{ color: "red" }}>
                                  {errors.email.message}
                                </span>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item md={6} xs={12} sx={{ mb: 2 }}>
                            <Typography sx={{ fontSize: "18px", mb: 1 }}>
                              Phone Number:
                            </Typography>
                            <FormControl sx={{ width: "90%" }}>
                              <TextField
                                variant="standard"
                                placeholder="Enter Your Phone Number"
                                type="number"
                                {...register("phone", {
                                  required: "Phone Number is Required",
                                  pattern: {
                                    value:
                                      /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/,
                                    message:
                                      "Entered Value Does not Match Phone Number Format",
                                  },
                                })}
                                InputProps={{
                                  style: { fontSize: 20 },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <PhoneIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {errors.phone && (
                                <span style={{ color: "red" }}>
                                  {errors.phone.message}
                                </span>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item md={6} xs={12} sx={{ mb: 2 }}>
                            <Typography sx={{ fontSize: "18px", mb: 1 }}>
                              Gender:
                            </Typography>
                            <FormControl sx={{ width: "90%" }}>
                              <RadioGroup row>
                                <FormControlLabel
                                  value="male"
                                  {...register("gender", {
                                    required: "Gender is Required",
                                  })}
                                  control={<Radio />}
                                  label="Male"
                                />
                                <FormControlLabel
                                  value="female"
                                  {...register("gender", {
                                    required: "Gender is Required",
                                  })}
                                  control={<Radio />}
                                  label="Female"
                                />
                                <FormControlLabel
                                  value="other"
                                  {...register("gender", {
                                    required: "Gender is Required",
                                  })}
                                  control={<Radio />}
                                  label="Other"
                                />
                              </RadioGroup>
                              {errors.gender && (
                                <span style={{ color: "red" }}>
                                  {errors.gender.message}
                                </span>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box sx={{ mb: 2 }}>
                        <div>
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{
                              mt: 1,
                              mr: 1,
                              float: "right",
                              fontSize: "15px",
                            }}
                          >
                            Next
                          </Button>
                        </div>
                      </Box>
                    </Box>
                  )}
                  {activeStep === 1 && (
                    <Box component={"form"} onSubmit={handleSubmit2(onSubmit)}>
                      <Box
                        sx={{
                          border: "1px solid #dfd9d9",
                          borderRadius: "10px",
                          p: 2,
                          mt: 2,
                        }}
                      >
                        <Typography sx={{ fontSize: "20px", mb: 2 }}>
                          Pickup Details:
                        </Typography>
                        <Grid container>
                          <Grid item md={4} xs={12} sx={{ mb: 2 }}>
                            {/* <Typography sx={{ fontSize: "18px", mb: 2 }}>
                            District:
                          </Typography> */}
                            <FormControl
                              variant="standard"
                              sx={{ width: "90%" }}
                            >
                              <Autocomplete
                                options={District}
                                getOptionLabel={(option) => option.name}
                                {...register2("pDistrict", {
                                  required: "District is Required",
                                })}
                                onChange={(event, value) => {
                                  getNeighbourhoodPick(value);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} label="District" />
                                )}
                              />
                              {errors2?.pDistrict && (
                                <span role="alert" style={{ color: "red" }}>
                                  {errors2.pDistrict.message}
                                </span>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item md={4} xs={12} sx={{ mb: 2 }}>
                            {/* <Typography sx={{ fontSize: "18px", mb: 2 }}>
                          Neighbourhood:
                          </Typography> */}
                            <FormControl
                              variant="standard"
                              sx={{ width: "90%" }}
                            >
                              <Autocomplete
                                options={districtByNeighbourhood}
                                getOptionLabel={(option) => option.name}
                                {...register2("pNeighbourhood", {
                                  required: "Neighbourhood is Required",
                                })}
                                onChange={(event, value) => {
                                  setValue("pNeighbourhood", value.id);
                                  clearErrors("pNeighbourhood");
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Neighbourhood"
                                  />
                                )}
                              />
                              {errors2?.pNeighbourhood && (
                                <span role="alert" style={{ color: "red" }}>
                                  {errors2.pNeighbourhood.message}
                                </span>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item md={4} xs={12} sx={{ mb: 2 }}>
                            {/* <Typography sx={{ fontSize: "18px", mb: 2 }}>
                            Pickup Time:
                          </Typography> */}
                            <FormControl
                              variant="standard"
                              sx={{ width: "90%" }}
                            >
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <TimePicker
                                  label="Pickup Time"
                                  value={timeValue}
                                  {...register2("pTime", {
                                    required: "PickUp Time is Required",
                                  })}
                                  onChange={handleChange}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                              {errors2?.pTime && (
                                <span role="alert" style={{ color: "red" }}>
                                  {errors2.pTime.message}
                                </span>
                              )}
                            </FormControl>
                          </Grid>
                          <Box sx={{display:'flex',justifyContent:'space-between',width:'81%'}}>
                          <Typography sx={{ fontSize: "20px", mb: 2 }}>
                            DropOff Details:
                          </Typography>
                          <Typography sx={{ fontSize: "20px", mb: 2, display:{xs:'none',md:'block'} }}>
                             Days You Travel:
                          </Typography>
                          </Box>
                          <Grid container>
                            <Grid item md={4} xs={12} sx={{ mb: 2 }}>
                              <FormControl
                                variant="standard"
                                sx={{ width: "90%" }}
                              >
                                <Autocomplete
                                  options={District}
                                  getOptionLabel={(option) => option.name}
                                  {...register2("dDistrict", {
                                    required: "District is Required",
                                  })}
                                  onChange={(event, value) => {
                                    getNeighbourhoodDrop(value);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} label="District" />
                                  )}
                                />
                                {errors2?.dDistrict && (
                                  <span role="alert" style={{ color: "red" }}>
                                    {errors2.dDistrict.message}
                                  </span>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12} sx={{ mb: 2 }}>
                              {/* <Typography sx={{ fontSize: "18px", mb: 2 }}>
                             Neighbourhood:
                          </Typography> */}
                              <FormControl
                                variant="standard"
                                sx={{ width: "90%" }}
                              >
                                <Autocomplete
                                  options={districtByNeighbourhoodD}
                                  getOptionLabel={(option) => option.name}
                                  {...register2("dNeighbourhood", {
                                    required: "Neighbourhood is Required",
                                  })}
                                  onChange={(event, value) => {
                                    setValue("dNeighbourhood", value.id);
                                    clearErrors("dNeighbourhood");
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Neighbourhood"
                                    />
                                  )}
                                />
                                {errors2?.dNeighbourhood && (
                                  <span role="alert" style={{ color: "red" }}>
                                    {errors2.dNeighbourhood.message}
                                  </span>
                                )}
                              </FormControl>
                            </Grid>
                            <Typography sx={{ fontSize: "20px", mb: 2, display:{xs:'block',md:'none'} }}>Days You Travel:</Typography>
                            <Grid item md={4} xs={12} sx={{ mb: 2 }}>
                              <Grid container>
                                <Grid md={4}>
                                  {travelDays.findIndex((e) => e === "Mon") !==
                                  -1 ? (
                                    <Box
                                      sx={[
                                        style.box,
                                        {
                                          color: "white",
                                          backgroundColor: "#1583CA",
                                        },
                                      ]}
                                      onClick={() => handleDayYouTravel("Mon")}
                                    >
                                      Monday
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={style.box}
                                      onClick={() => handleDayYouTravel("Mon")}
                                    >
                                      Monday
                                    </Box>
                                  )}
                                </Grid>
                                <Grid md={4}>
                                  {travelDays.findIndex((e) => e === "Tue") !==
                                  -1 ? (
                                    <Box
                                      sx={[
                                        style.box,
                                        {
                                          color: "white",
                                          backgroundColor: "#1583CA",
                                        },
                                      ]}
                                      onClick={() => handleDayYouTravel("Tue")}
                                    >
                                      Tuesday
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={style.box}
                                      onClick={() => handleDayYouTravel("Tue")}
                                    >
                                      Tuesday
                                    </Box>
                                  )}
                                </Grid >
                                <Grid md={4}>
                                  {travelDays.findIndex((e) => e === "Wed") !==
                                  -1 ? (
                                    <Box
                                      sx={[
                                        style.box,
                                        {
                                          color: "white",
                                          backgroundColor: "#1583CA",
                                        },
                                      ]}
                                      onClick={() => handleDayYouTravel("Wed")}
                                    >
                                      Wednesday
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={style.box}
                                      onClick={() => handleDayYouTravel("Wed")}
                                    >
                                      Wednesday
                                    </Box>
                                  )}
                                </Grid>
                                <Grid md={4}>
                                  {travelDays.findIndex((e) => e === "Thu") !==
                                  -1 ? (
                                    <Box
                                      sx={[
                                        style.box,
                                        {
                                          color: "white",
                                          backgroundColor: "#1583CA",
                                        },
                                      ]}
                                      onClick={() => handleDayYouTravel("Thu")}
                                    >
                                      Thursday
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={style.box}
                                      onClick={() => handleDayYouTravel("Thu")}
                                    >
                                      Thursday
                                    </Box>
                                  )}
                                </Grid>
                                <Grid md={4}>
                                  {travelDays.findIndex((e) => e === "Fri") !==
                                  -1 ? (
                                    <Box
                                      sx={[
                                        style.box,
                                        {
                                          color: "white",
                                          backgroundColor: "#1583CA",
                                        },
                                      ]}
                                      onClick={() => handleDayYouTravel("Fri")}
                                    >
                                      Friday
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={style.box}
                                      onClick={() => handleDayYouTravel("Fri")}
                                    >
                                      Friday
                                    </Box>
                                  )}
                                </Grid>
                                <Grid md={4}>
                                  {travelDays.findIndex((e) => e === "Sat") !==
                                  -1 ? (
                                    <Box
                                      sx={[
                                        style.box,
                                        {
                                          color: "white",
                                          backgroundColor: "#1583CA",
                                        },
                                      ]}
                                      onClick={() => handleDayYouTravel("Sat")}
                                    >
                                      Saturday
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={style.box}
                                      onClick={() => handleDayYouTravel("Sat")}
                                    >
                                      Saturday
                                    </Box>
                                  )}
                                </Grid>
                                <Grid md={4}>
                                  {travelDays.findIndex((e) => e === "Sun") !==
                                  -1 ? (
                                    <Box
                                      sx={[
                                        style.box,
                                        {
                                          color: "white",
                                          backgroundColor: "#1583CA",
                                        },
                                      ]}
                                      onClick={() => handleDayYouTravel("Sun")}
                                    >
                                      Sunday
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={style.box}
                                      onClick={() => handleDayYouTravel("Sun")}
                                    >
                                      Sunday
                                    </Box>
                                  )}
                                </Grid>

                              </Grid>
                            </Grid>
                          </Grid>
                          
                        </Grid>
                      </Box>
                      <Box sx={{ mb: 2 }}>
                        <div>
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{ mt: 1, mr: 1, float: "right" }}
                          >
                            Finish
                          </Button>
                        </div>
                      </Box>
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
          )}
        </Container>
      </Box>

      <Box sx={{ py: 6 }}>
        <Container>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: "2.4rem",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              Available For Your Smartphone.
            </Typography>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid item md={6} xs={12}>
                <Typography sx={{ color: "#6c757d" }}>
                  The wise man therefore always holds in these matters to this
                  of selection he rejects pleasures to other greater that id
                  pains to avoid worse.{" "}
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                mt: 3,
              }}
            >
              <Grid container sx={{ width: "60%" }}>
                <Grid item md={6} xs={12}>
                  <img
                    src={Images.playstore}
                    alt="playstore"
                    width="230px"
                    data-aos="flip-right"
                    data-aos-easing="linear"
                    data-aos-duration="1200"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <img
                    src={Images.istore}
                    alt="appstore"
                    width="230px"
                    data-aos="flip-right"
                    data-aos-easing="linear"
                    data-aos-duration="1200"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 3 }}></Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Home;
