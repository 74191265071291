import { AppBar,Container,Toolbar,} from '@mui/material'

import React from 'react'
import ScrollToColor from '../ScrollToColor';
import Images from '../../assets/Images';
import { Link } from 'react-router-dom';
const Header2 = () => {
  return (
    <>
    <ScrollToColor>
      <AppBar position="sticky">
        <Container>
          <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link  to='/' 
            style={{
              cursor: 'pointer'
              }}
              >
              <img src={Images.logo} width='60px'/>
            </Link>
            
          </Toolbar>
          </Container >
      </AppBar>
      </ScrollToColor>
    </>
  )
}

export default Header2