 const Neighbourhood=[
    {
        id:'1',
        name:'Water Pump',
        district:'1'
    },
    {
        id:'2',
        name:'Nazimabad',
        district:'1'
    },
    {
        id:'3',
        name:'Hadi Market',
        district:'1'
    },
    {
        id:'4',
        name:'North Nazimabad',
        district:'1'
    },
    {
        id:'5',
        name:'Ayesha Manzil',
        district:'1'
    },
    {
        id:'6',
        name:'Garden',
        district:'3'
    },
    {
        id:'7',
        name:'Kharadar',
        district:'3'
    },
    {
        id:'8',
        name:'Civil Line',
        district:'3'
    },

    {
        id:'9',
        name:'Clifton',
        district:'3'
    },
    {
        id:'10',
        name:'City Railway Colony',
        district:'3'
    },
    {
        id:'11',
        name:'Lyari Town',
        district:'4'
    },
    {
        id:'12',
        name:'Baldia Town',
        district:'4'
    },
    {
        id:'13',
        name:'Kemari Town',
        district:'4'
    },
    {
        id:'14',
        name:'Orangi Town',
        district:'4'
    },
    {
        id:'15',
        name:'SITE Town',
        district:'4'
    },
    {
        id:'16',
        name:'Shanti Nagar',
        district:'2'
    },
    {
        id:'17',
        name:'Safoora Goth',
        district:'2'
    },
    {
        id:'18',
        name:'PECHS II',
        district:'2'
    },
    {
        id:'19',
        name:'SITE Town',
        district:'2'
    },
    {
        id:'20',
        name:'Gulistan-e-Johar',
        district:'2'
    },
    {
        id:'21',
        name:'Gulshan-e-Iqbal I',
        district:'2'
    },
    {
        id:'22',
        name:'Gulshan-e-Iqbal II',
        district:'2'
    },
]

export default Neighbourhood